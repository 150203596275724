<template>
  <div>
    <!-- 轮播图 -->
    <div class="banner">
      <!-- 先占个位 -->
    </div>

    <!-- 消息通知，以及登录模块 -->
    <div class="content">
      <div class="content-left">
        <div class="content-title">
          <span class="content-span">新闻动态</span>
          <a href="" class="content-title-a">+MORE</a>
        </div>

        <div class="content-left-div">
          <ul>
            <li class="content-left-li">
              <a href="#"
                ><span>[02-08]</span>
                2022南航学位航学位与研究生教育十22南航航学位与研究生教育十22南航学学位与育十22南研究生教育十</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[12-13]</span>
                研究生院/研工部党支部组织全体教职工参观立德树人研工部党支部组织全体教职工参观立德树</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[12-06]</span
                >我校举行2022年研究生导师培训会举行2022年研究生导师培训举行2022年研究生导师培训举行2022年研究生导师培训</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五好”导学团队经验悦享会暨行2022生“五好”导学团队经验悦行2022年研究生“五好”导学团队经验悦</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[11-28]</span> 我校成功举办第十一届“天宫杯”
                研究生创新实验研究生创新研究生创新研究生创新研究生创新研究生创新竞</a
              >
            </li>

            <li class="content-left-li">
              <a href="#"
                ><span>[02-08]</span>
                2022南航学位与研究生教育位与研究生位与研究生位与研究生位与研究生位与研究生位与研究生十大亮点工作</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[12-13]</span>
                研究生院/研工部党支部组织全体教职工参观立德树人研工部党支部组织全体教职工研工部党支部组织全体教职工</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[12-06]</span
                >我校举行2022年研究生导师培训会行2022年研究生导师行2022年研究生导师行2022年研究生导师</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五好”导学团队经验悦享会暨研究生“五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[11-28]</span> 我校成功举办第十一届“天宫杯”
                研究生创新实验竞届“天宫杯” 研究生创届“天宫杯” 研究生创届“天宫杯”
                研究生创</a
              >
            </li>

            <li class="content-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五好”导学团队经验悦享会暨研究生“五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
            <li class="content-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五好”导学团队经验悦享会暨研究生“五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="content-right">
        <div class="banner2"></div>
        <ul class="content-ul">
          <li class="">
            <a href="#" target="_blank">
              <!-- <img src="@/static/img/stu.png" /> -->
              <div>学生登录</div>
            </a>
          </li>
          <li class="">
            <a href="#" target="_blank">
              <!-- <img src="@/static/img/tec.png" /> -->
              <div>教师登录</div>
            </a>
          </li>
          <li class="">
            <a href="#" target="_blank">
              <!-- < img src="@/static/img/mana.png" /> -->
              <div>管理系统</div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- 第二块 -->
    <div class="content2">
      <div class="content2-left">
        <div class="content2-left-title">
          <span class="content2-left-span">比赛动态</span>
          <a href="" class="content2-left-title-a">+MORE</a>
        </div>

        <div class="content2-left-div">
          <ul>
            <li class="content2-left-li">
              <a href="#"
                ><span>[02-08]</span>
                2022南航学位航学位与研究生南航学学位与育十22南研究生教育十</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[12-13]</span>
                研究生院/研工部党支部组支部组织全体教职工参观立德树</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[12-06]</span
                >我校举行2022年研究生导2年研究生导师培训举行2022年研究生导师培训</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“好”导学团队经验悦行2022年研究生“五好”导学团队经验悦</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[11-28]</span>
                我校成功举办第十一创新研究生创新研究生创新研究生创新竞</a
              >
            </li>

            <li class="content2-left-li">
              <a href="#"
                ><span>[02-08]</span>
                2022南航学位与研究生研究生位与研究生位与研究生十大亮点工作</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[12-13]</span>
                研究生院/研工部党支支部组织全体教职工研工部党支部组织全体教职工</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[12-06]</span
                >我校举行2022年研究2022年研究生导师行2022年研究生导师</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生暨研究生“五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[11-28]</span> 我校成功举办第十一杯” 研究生创届“天宫杯”
                研究生创届“天宫杯” 研究生创</a
              >
            </li>

            <li class="content2-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
            <li class="content2-left-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五好“五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="content2-right">
        <div class="content2-right-title">
          <span class="content2-right-span">比赛结果</span>
          <a href="#" class="content2-right-title-a">+MORE</a>
        </div>

        <div class="content2-right-div">
          <ul>
            <li class="content2-right-li">
              <a href="#"
                ><span>[02-08]</span>
                2022南航学位航学位与研究生南航学学位与育十22南研究生教育十</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[12-13]</span>
                研究生院/研工部党支部组支部组织全体教职工参观立德树</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[12-06]</span
                >我校举行2022年研究生导2年研究生导师培训举行2022年研究生导师培训</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“好”导学团队经验悦行2022年研究生“五好”导学团队经验悦</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[11-28]</span>
                我校成功举办第十一创新研究生创新研究生创新研究生创新竞</a
              >
            </li>

            <li class="content2-right-li">
              <a href="#"
                ><span>[02-08]</span>
                2022南航学位与研究生研究生位与研究生位与研究生十大亮点工作</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[12-13]</span>
                研究生院/研工部党支支部组织全体教职工研工部党支部组织全体教职工</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[12-06]</span
                >我校举行2022年研究2022年研究生导师行2022年研究生导师</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生暨研究生“五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[11-28]</span> 我校成功举办第十一杯” 研究生创届“天宫杯”
                研究生创届“天宫杯” 研究生创</a
              >
            </li>

            <li class="content2-right-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
            <li class="content2-right-li">
              <a href="#"
                ><span>[12-01]</span
                >我校举行2022年研究生“五好“五好”导学团队经研究生“五好”导学团队经</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footer style="margin-top: auto; text-align: center">
      <!--  -->
      <!-- <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >ICP备案/许可证号：冀ICP备2024048640号-2</a
      > -->
      <!-- dadahytop -->
      <span class="span-img" style="margin-right: 15px" @click="go"
        >营业执照</span
      >
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
        >ICP备案/许可证号：冀ICP备2024048640号</a
      >
    </footer>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";

const route = useRoute();
const router = useRouter();
const go = () => {
  router.push("/img");
};
</script>
<style>
a {
  color: #000;
}
a:hover,
.span-img:hover {
  color: rgb(54, 54, 222);
  cursor: pointer;
}
/* 初始配置 */
* {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
img {
  display: block;
}
a {
  text-decoration: none;
  color: #000;
}
h1,
h2,
h3 {
  font-size: 16px;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}

/* 浮动 */
.l {
  float: left;
}
.r {
  float: right;
}
.clear::after {
  content: "";
  display: block;
  clear: both;
}

/* 版心 */
.container {
  width: 1080px;
  margin: 0 auto;
  position: relative;
}
/* 通栏 */
.container-fluid {
  width: 100%;
}

/* 页面样式 */
.banner {
  height: 450px;
  width: 100%;
  overflow: hidden;
  background-image: url("@/assets/banner.jpg");
  background-repeat: no-repeat;
  background-position: center;
}
.banner2 {
  margin-top: 55px;
  margin-left: 20px;
  height: 350px;

  /* background-image: url("../static/img/ban2.jpg"); */
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

/* 内容样式 */
.content {
  height: 500px;
  width: 1400px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
}
.content-left {
  width: 980px;
  height: 500px;
}
.content-right {
  height: 500px;
  width: 420px;
}
.content-title {
  width: 940px;
  position: relative;
  margin-top: 20px;
  /* background: url(@/static/img/fj.png) no-repeat left center; */
  padding-left: 40px;
  font-family: "Times New Roman", "Arial", "Microsoft YaHei", "SimSun",
    sans-serif;
  line-height: 1.5;

  color: #404040;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  border-bottom: 3px solid #00408a;
  display: inline-block;
  height: 40px;
}
.content-title-a {
  color: #b08a37;
  font-size: 15px;
  float: right;
  margin-top: 20px;
  margin-right: 11px;
}

.content-span {
  color: #404040;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  border-bottom: 3px solid #00408a;
  display: inline-block;
  height: 37px;
}
.content-left-div {
  height: 440px;
  width: 100%;
}
.content-left-li {
  border-bottom: 1px dotted #000;
  width: 100%;
  color: #000;
  height: 35px;
  display: flex;
  align-items: center;
}
.content-left-li span {
  margin-right: 30px;
}
.content-ul {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100px;
  background-color: #d2ecff;
  border-radius: 15px;
}
.content-ul a {
  display: flex;
  width: 130px;
  font-size: 15px;
  align-items: center;
  justify-content: center;
}

/* 第二块内容样式 */
.content2 {
  height: 500px;
  width: 1400px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
}
.content2-left {
  width: 675px;
  height: 500px;
  margin-right: 25px;
}
.content2-right {
  width: 675px;
  height: 500px;
  margin-left: 25px;
}
.content2-left-title {
  width: 100%;
  position: relative;
  margin-top: 20px;
  /* background: url(@/static/img/fj.png) no-repeat left center; */
  padding-left: 40px;
  font-family: "Times New Roman", "Arial", "Microsoft YaHei", "SimSun",
    sans-serif;
  line-height: 1.5;

  color: #404040;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  border-bottom: 3px solid #00408a;
  display: inline-block;
  height: 40px;
}
.content2-left-title-a {
  color: #b08a37;
  font-size: 15px;
  float: right;
  margin-top: 20px;
  margin-right: 11px;
}

.content2-left-span {
  color: #404040;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  border-bottom: 3px solid #00408a;
  display: inline-block;
  height: 37px;
}
.content2-left-div {
  height: 500px;
  width: 100%;
}
.content2-left-li {
  border-bottom: 1px dotted #000;
  width: 100%;
  color: #000;
  height: 35px;
  display: flex;
  align-items: center;
}
.content2-left-li span {
  margin-right: 30px;
}

/* 第三块内容样式 */
.content2 {
  height: 500px;
  width: 1400px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
}
.content2-left {
  width: 675px;
  height: 500px;
  margin-right: 25px;
}
.content2-right {
  width: 675px;
  height: 500px;
  margin-left: 25px;
}
.content2-right-title {
  width: 100%;
  position: relative;
  margin-top: 20px;
  /* background: url(@/static/img/fj.png) no-repeat left center; */
  padding-left: 40px;
  font-family: "Times New Roman", "Arial", "Microsoft YaHei", "SimSun",
    sans-serif;
  line-height: 1.5;

  color: #404040;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  border-bottom: 3px solid #00408a;
  display: inline-block;
  height: 40px;
}
.content2-right-title-a {
  color: #b08a37;
  font-size: 15px;
  float: right;
  margin-top: 20px;
  margin-right: 11px;
}

.content2-right-span {
  color: #404040;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  border-bottom: 3px solid #00408a;
  display: inline-block;
  height: 37px;
}
.content2-right-div {
  height: 500px;
  width: 100%;
}
.content2-right-li {
  border-bottom: 1px dotted #000;
  width: 100%;
  color: #000;
  height: 35px;
  display: flex;
  align-items: center;
}
.content2-right-li span {
  margin-right: 30px;
}
</style>
