import { createRouter,createWebHistory,createWebHashHistory } from 'vue-router'
import File1 from '../views/index.vue'
import File2 from '../views/img.vue'

const routes = [
    {
        path: '/',
        component:File1
    },
    {
        path: '/img',
        component:File2
    },
 
]

const router = createRouter({
    // history: createWebHistory(),
    history:createWebHashHistory(),
    routes,
})

export default router;

